<template>
  <fragment>
    <Search />
    <Themas />
    <Selector v-if="showSelector" />
  </fragment>
</template>

<script>
// @ is an alias to /src
import Search from "@/components/Search.vue";
import Themas from "@/components/Themas.vue";
import Selector from "@/components/Selector.vue";

export default {
  name: "Home",
  components: {
    Search,
    Themas,
    Selector
  },
  data() {
    return {
      showSelector: false
    }
  },
  mounted() {
    this.$store.dispatch("logEventsForOrganisations");
       //this.$store.dispatch("LOG_VISIT");
        const organisatie = window.localStorage.getItem("organisation");
        const role = window.localStorage.getItem("role");
        
       if (organisatie == 'topaz' && role == null) {
         this.showSelector = true;
       }
  }
};
</script>
