<template>
  <div
    class="modal modal-video js-modal js-modal-video"
    :id="modalTrigger"
    data-animation="on"
  >
    <div
      class="modal-video__content max-width-xxl js-modal-video__content"
      role="alertdialog"
      tabindex="-1"
      aria-describedby="modalVideoDescription1"
    >
      <p class="sr-only" id="modalVideoDescription1">A video is being shown</p>

      <div class="media-wrapper">
        <iframe
          src=""
          class="js-modal-video__media"
          width="90vw"
          height="90vh"
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div class="modal-video__loader" aria-hidden="true">
      <svg class="icon icon--lg icon--is-spinning">
        <path
          d="M24,48A24,24,0,1,1,48,24,24.027,24.027,0,0,1,24,48ZM24,4A20,20,0,1,0,44,24,20.023,20.023,0,0,0,24,4Z"
          opacity="0.4"
        ></path>
        <path
          d="M48,24H44A20.023,20.023,0,0,0,24,4V0A24.028,24.028,0,0,1,48,24Z"
        ></path>
      </svg>
    </div>

    <button class="reset modal__close-btn js-modal__close">
      <svg class="icon" viewBox="0 0 16 16">
        <title>Close modal window</title>
        <g
          stroke-width="1"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
        >
          <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
          <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    modalId: String
  },
  mounted() {
    let frontEnd = document.createElement("script");
    frontEnd.setAttribute("src", "/scripts/modal.js");
    document.body.appendChild(frontEnd);
  },
  computed: {
    modalTrigger() {
      return this.$props.modalId;
    },
    
  }
};
</script>
