<template>
  <div
    class="preloader flex flex-column justify-center items-center"
    :data-theme="`${$store.state.themeslug}dark`"
  >
    <div class="container padding-y-md max-width-lg bg-transparent">
      <div class="grid gap-sm justify-center">
        <div class="col-12">
            <h1 class="text-center">Maak een keuze</h1>
        </div>
        <div class="col-3">
          <img @click="setRole('vrijwilliger')" class="cursor-pointer" src="@/assets/vrijwilliger.png" />
        </div>
        <div class="col-3">
          <img @click="setRole('naaste')" class="cursor-pointer" src="@/assets/naaste.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Selector",
    methods: {
      setRole(role) {
        window.localStorage.setItem('role', role);
       window.location.reload();
      },
    },
};
</script>
