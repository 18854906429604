<template>
  <div class="card__container padding-xs">
    <router-link
      :to="{ name: 'Content', params: { slug: slug, content: content } }"
      class="no-underline"
    >
      <div class="card__img-wrapper">
        <!-- <img
          loading="lazy"
          :src="content.image"
          class="card-img-top"
        /> -->

        <Img
          :url="`https://amarhnhgeo.cloudimg.io/v7/${content.image}?func=crop&w=280&h=130`"
        />
      </div>
      <div class="position-relative">
        <span class="card__type-indicator padding-x-sm padding-y-xs"
          ><i :class="`icon-${type}`"></i><strong> {{ type }}</strong></span
        >

        <span
          @click.stop.prevent="toggleFav()"
          class="card__type-indicator--fav padding-xs"
          :class="isFav ? 'bg-accent' : 'bg-white'"
          ><i v-if="!isFav" class="icon-star"></i>
          <i v-if="isFav" class="icon-star-fill color-white"></i
        ></span>
      </div>

      <h5
        class="card-title margin-bottom-xs margin-top-md font-bold no-underline"
      >
        {{ content.title }}
      </h5>
    </router-link>
    <!-- </a> -->
  </div>
</template>

<script>
import Img from "@/components/Img.vue";
import slugify from "slugify";

export default {
  name: "ContentCard",
  components: {
    Img,
  },
  props: {
    uid: String,
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleFav() {
      this.$store.dispatch("UPDATE_FAVS", { uid: this.$props.uid });
    },
  },
  computed: {
    isFav() {
      return this.$store.state.favorites.indexOf(this.uid) > -1;
    },
    content() {
      return this.$props.data;
    },
    type() {
      if (this.$props.data.subthema === "freelearning") {
        return "Freelearning";
      }
      const type =
        this.$props.data.type == "e-learning"
          ? "Online training"
          : this.$props.data.type;
      return type;
    },
    slug() {
      return slugify(this.$props.data.title, { lower: true });
    },
    subthema() {
      return this.$props.data.subthema.split("/")[1];
    },
  },
};
</script>
